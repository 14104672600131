import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { TranslatesService, ILang } from '@shared/translates';
import { IMAGES, SVGS } from '@shared/globals';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  public navList: { key: string; route: string }[];
  public imagePath: string = IMAGES;
  public svgPath: string = SVGS;
  public showLanguageList: boolean = false;
  public langList$: Observable<ILang[]>;
  public currentLang: string;
  public activeRoute: string;
  private routerSubscription: Subscription;

  constructor(private _translatesService: TranslatesService, private _router: Router) {
    this.routerSubscription = this._router.events.subscribe((event: any) => {
      if (event.id && event.urlAfterRedirects && this._router !== event.id) {
        this.activeRoute = event.urlAfterRedirects;
      }
    });
  }

  ngOnInit(): void {
    this.langList$ = this._translatesService.getLangList();
    this.currentLang = this._translatesService.getCurrentLang();
    this.navList = [
      { key: 'header.home', route: '/home' },
      { key: 'header.app', route: '/app' },
      { key: 'header.events', route: '/events' },
      { key: 'header.taste', route: '/taste' },
      { key: 'header.blog', route: '/blog' },
      { key: 'header.about', route: '/about' },
    ];
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  public changeLang(code: string): void {
    this._translatesService.changeLang(code);
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.showLanguageList = false;
  }

  navigate(nav: { key: string; route: string }) {
    // nav.route === '/blog'
    //   ? window.location.replace('https://georgian.wine/blog')
    //   : this._router.navigate([nav.route]);
    if (nav.route === '/blog') {
      window.location.replace('https://georgian.wine/blog');
    } else if (nav.route === '/home') {
      this._router.navigate([nav.route]);
    }
  }
}
