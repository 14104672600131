import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-inline-style',
  template: '',
  styleUrls: ['./inline-style.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class InlineStyleComponent {
}
