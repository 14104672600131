import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API } from '../../utils';

@Injectable()
export class TasteService {
  constructor(private http: HttpClient) {}
  public getWines(query: any): Observable<any> {
    return this.http.get(API + 'wines', {
      params: query,
    });
  }
}
