import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable, AfterContentInit } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { TranslatesService, ILang } from '@shared/translates';

@Injectable()
export class GeneralInterseptor implements HttpInterceptor {
  constructor(private translatesService: TranslatesService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const cloned = req.clone({
      headers: req.headers.set(
        'Content-Language',
        this.getLanguageName(this.translatesService.getCurrentLang()),
      ),
      withCredentials: true,
    });
    return next.handle(cloned).pipe(
      catchError((err) => {
        console.log(err);
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 401:
              console.log('interceptor 401');
              break;
            case 500:
              console.log('interceptor 500');
              break;
            default:
              console.log('interceptor unknown error');
              break;
          }
        }
        return throwError(err);
      }),
    );
  }

  getLanguageName(code: string) {
    switch (code) {
      case 'ENG':
        return 'English';
      case 'RUS':
        return 'Russian';
      case 'GEO':
        return 'Georgian';
      default:
        return 'English';
    }
  }
}
