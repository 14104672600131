import { Routes, RouterModule } from '@angular/router';
import { MetaGuard } from '@ngx-meta/core';

import { WrapperComponent } from '@shared/layouts/wrapper/wrapper.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '',
    component: WrapperComponent,
    canActivateChild: [MetaGuard],
    children: [
      {
        path: 'home',
        loadChildren: './pages/landing-page/landing-page.module#LandingPageModule',
      },
      { path: 'taste', loadChildren: './pages/taste/taste.module#TasteModule' },
      { path: 'android', loadChildren: './pages/download/download.component#DownloadModule' },
      {
        path: 'wine/:id',
        loadChildren: './pages/wine-detail/wine-detail.module#WineDetailModule',
      },
      // { path: 'home', loadChildren: './home/home.module#HomeModule' },
      {
        path: 'mock',
        loadChildren: './mock-server-browser/mock-server-browser.module#MockServerBrowserModule',
      },
      { path: 'back', loadChildren: './transfer-back/transfer-back.module#TransferBackModule' },
      {
        path: 'static/back',
        loadChildren: './transfer-back/transfer-back.module#TransferBackModule',
      },
      { path: '**', loadChildren: './not-found/not-found.module#NotFoundModule' },
    ],
  },
];
// must use {initialNavigation: 'enabled'}) - for one load page, without reload
export const AppRoutes = RouterModule.forRoot(routes, { initialNavigation: 'enabled' });
